import { useEffect, useState } from 'react'
import CompletedRow from '../board/CompletedRow'
import Loading from '../misc/Loading'
import BaseModal from './BaseModal'
import MiniHeader from '../misc/MiniHeader'
import { LEARN_MORE_TEXT } from '../../constants/strings'
import { getHistoricPuzzleAsync, msInDay, isAheadOfTodaysPuzzle } from '../../lib/puzzles'
import { PuzzleData } from '../../lib/PuzzleData'
import PuzzleContext from '../../context/PuzzleContext.js'
import { NumberStatus } from '../../lib/statuses'
import { LEFT_ARROW, RIGHT_ARROW } from '../../constants/strings'
import { decodeSolution } from '../../lib/utils'
import { MIDDLE_DOT } from '../../constants/strings'
import { observer } from 'mobx-react'
import { isEmpty } from 'lodash-es'
import { ExternalLinkIcon } from '@heroicons/react/outline'


type Props = {
    isOpen: boolean
    handleClose: () => void
}

function getDayOfWeek(day: number): string {
    const daysOfTheWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return daysOfTheWeek[day] || 'Sat'
}

function getMonth(month: number): string {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    return months[month] || 'Dec'
}

function formatDate(date: Date): string {
    const d = new Date(date)
    return `${getDayOfWeek(d.getDay())}, ${getMonth(d.getMonth())} ${d.getDate()}`
}

function formatPrevDay(date: Date) : string {
    const d = new Date(date.getTime() - msInDay)
    return `${getDayOfWeek(d.getDay())}`
}

function formatNextDay(date: Date) : string {
    const d = new Date(date.getTime() + msInDay)
    return `${getDayOfWeek(d.getDay())}`
}

const HistoryModal = ({ isOpen, handleClose }: Props) => {
    const [pastPuzzles, setPastPuzzles] = useState<PuzzleData[]>([{ UserName: '', UserAvatar: '', UserLocation: '', Date: new Date(), Puzzle: '',
        Topic: '', Hints: [], Trivia: '', VerifyUrl: '', ActivePuzzleCount: 0, NumPlays: 0, NumWins: 0, AmountWon: 0 }])
    const [selectedDayIndex, setSelectedDayIndex] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const selectedPuzzle = pastPuzzles[selectedDayIndex]
    const solution = decodeSolution(selectedPuzzle.Puzzle)

    const triviaClasses = 'flex justify-center items-center mb-2 mx-auto text-base w-full px-3 py-[4px] leading-5 align-text-top whitespace-pre-line no-text-select bg-unknown text-black dark:bg-unknown-dark dark:text-white'
   
    const PreviousDay = async () => {
        const index = selectedDayIndex + 1
        setSelectedDayIndex(index)

        if (pastPuzzles.length < index + 2) {
            const data = await getHistoricPuzzleAsync(index + 1)
            if (data != null) {
                const puzzles = pastPuzzles
                puzzles.push(data)
                //Hack to get React to update UI, not happy with this
                setPastPuzzles([...puzzles])
            }
        }
    }

    const NextDay = () => {
        setSelectedDayIndex((selectedDayIndex - 1 < 0 ? 0 : selectedDayIndex - 1))
    }

    // Display up to 30 days of history
    const disablePreviousDay = (pastPuzzles.length < selectedDayIndex + 2) || (pastPuzzles.length > 30 && selectedDayIndex === 30)
    const disableNextDay = selectedDayIndex <= 0

    useEffect(() => {
        const fetchData = async () => {
            // This will only load once the files since once loaded isLoading would be false
            if (isOpen && isLoading) {
                const data: PuzzleData[] = []
                const yesterday = await getHistoricPuzzleAsync(isAheadOfTodaysPuzzle ? 1 : 0)
                const twoDaysAgo = await getHistoricPuzzleAsync(isAheadOfTodaysPuzzle ? 2 : 1)
                if (yesterday != null)
                    data.push(yesterday)
                if (twoDaysAgo != null)
                    data.push(twoDaysAgo)
                setPastPuzzles(data)
                setIsLoading(false)
            }
        }
        fetchData()
        // TODO: gracefully handle missing/unexpected JSON - any more needed?
        // Below line is catching "TypeError: undefined is not an object (Evaluating 'e.replaceAll')
        //.catch(error => alert(error))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    if (!isOpen)
        return null

    const winPercentage : number = Number((selectedPuzzle.NumWins / selectedPuzzle.NumPlays * 100).toFixed(1))

    return (
        <BaseModal title="Past Wumbers" isOpen={isOpen} handleClose={handleClose} containerClass='min-h-[300px]'>
            {
            isLoading ?
                <Loading />
            :   pastPuzzles.length > 0 && (
                <PuzzleContext.Provider value={selectedPuzzle}>
                    <div className='flex flex-col'>
                        <div className='flex justify-between'>
                            <div className='grow basis-0 flex justify-end'>
                                {!disablePreviousDay && <button className="link pr-3 text-black dark:text-white focus:outline-none" onClick={PreviousDay}>{LEFT_ARROW} {formatPrevDay(selectedPuzzle.Date)}</button>}
                            </div>
                            <div className='grow basis-16 flex justify-center'>
                                {formatDate(selectedPuzzle.Date)}
                            </div>
                            <div className='grow basis-0 flex justify-start'>
                                {!disableNextDay && <button className="pl-3 text-black dark:text-white focus:outline-none" onClick={NextDay}>{formatNextDay(selectedPuzzle.Date)} {RIGHT_ARROW}</button>}
                            </div>
                        </div>
                        {selectedPuzzle.NumPlays > 0 &&
                            <div className="text-base mb-0 mt-3 mb-2 text-center text-black dark:text-white">
                                {selectedPuzzle.NumPlays.toLocaleString("en-US")} {selectedPuzzle.NumPlays === 1 ? "play" : "plays"}&nbsp;&nbsp;{MIDDLE_DOT}&nbsp;&nbsp;
                                {isNaN(winPercentage) ? 0 : winPercentage}% wins
                            </div>
                        }
                        {(selectedPuzzle.NumPlays === undefined || selectedPuzzle.NumPlays === 0) &&
                            <div className="text-base mb-0 mt-3 mb-2 text-center text-black dark:text-white">
                                &nbsp;
                            </div>
                        }
                        <MiniHeader title={selectedPuzzle.Topic} classes='text-sm pt-3 line-cell' />
                        <div className='flex flex-col items-center mx-1 mt-1'>
                            <div className='flex justify-center items-center text-center text-white dark:text-slate-300 max-w-10 mb-2 h-[40px] align-text-top'>
                                <div className={`${triviaClasses}`}>
                                    {selectedPuzzle.Trivia}
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center mb-4 mt-2 iPhonePro:scale-110">
                            <CompletedRow key={solution} guess={solution} proximity={NumberStatus.CORRECT} isRevealing standAlone/>
                        </div>
                        <div className="mb-3 text-xs text-center text-slate-400">
                            {/* Non-sweepstakes display */}
                            {(selectedPuzzle.UserName === 'Wumber BETA' || selectedPuzzle.AmountWon <= 0 || selectedPuzzle.AmountWon == null) &&
                                <span>
                                    by {selectedPuzzle.UserName}
                                </span>
                            }
                            {/* Sweepstakes winner display */}
                            {selectedPuzzle.UserName !== 'Wumber BETA' && selectedPuzzle.AmountWon > 0 &&
                                <span>
                                    {selectedPuzzle.UserName}&nbsp;won <strong>${selectedPuzzle.AmountWon.toLocaleString("en-US")}</strong>
                                </span>
                            }
                            <div className={'flex justify-center items-center text-sm no-text-select text-slate-400 pt-1 mb-2'}>
                                <img className='w-8 rounded-full border-width-[2px]' src={selectedPuzzle.UserAvatar} alt="Avatar" />
                            </div>
                        </div>
                        <div className='flex justify-center items-center text-sm no-text-select pt-2'>
                            <button className='btn w-1/2 no-text-select' type="button" disabled={isEmpty(selectedPuzzle.VerifyUrl)}
                                    onClick={() => window.open(selectedPuzzle.VerifyUrl, '_blank')}>
                                <span className='relative pr-1 no-text-select'>{LEARN_MORE_TEXT}</span>
                                <ExternalLinkIcon className='inline w-4 h-4 -mt-1' />
                            </button>
                        </div>
                    </div>
                </PuzzleContext.Provider>
            )}
        </BaseModal>
    )
}

export default observer(HistoryModal)
